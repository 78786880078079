import React, { useState, useEffect } from "react";
import { Row, Col, Container, Modal } from "react-bootstrap";
import Course from "./Course";
import Loading from "./Loading";
import ContactModal from "./ContactModal";

const url = "https://backend.seinimlicht.de/wp-json/wp/v2/";

const Courses = () => {
	const [categories, setCategories] = useState([]);
	const [courses, setCourses] = useState([]);
	const [selectedCourse, setSelectedCourse] = useState(null);

	useEffect(() => {
		async function loadPosts() {
			const response = await fetch(`${url}posts?per_page=100`);
			if (!response.ok) return;

			const posts = await response.json();
			const filteredPosts = posts.filter((article) => article.categories[0] !== 8 && article.categories[0] !== 9);
			setCourses(filteredPosts);
		}

		async function loadCategories() {
			const response = await fetch(`${url}categories?per_page=100`);
			if (!response.ok) return;

			const categoriesList = await response.json();
			const filtered = categoriesList.filter((category) => [15, 6, 14, 13].includes(category.id) && category.slug !== "uncategorized");
			setCategories(filtered);
		}

		loadPosts();
		loadCategories();
	}, []);

	// Group courses by category
	const getCoursesByCategory = (categoryId) => {
		return courses.filter((course) => course.categories[0] === categoryId);
	};

	return (
		<div id="coursesTop" className="gray-background">
			{categories.length && courses.length ? (
				<section>
					<Container>
						<Row>
							<Col md={12}>
								<h2 className="display-6">COACHING | EVENTS | KURSE | Workshops</h2>
							</Col>
						</Row>

						{categories.map((category) => (
							<div key={category.id}>
								<Row>
									<Col md={12}>
										<h2 style={{ marginBottom: "10px", marginTop: "30px" }}>{category.name}</h2>
									</Col>
								</Row>
								<Row className="row-cols-1 row-cols-md-2 row-cols-lg-3 g-4">
									{getCoursesByCategory(category.id).map((course) => (
										<Col key={course.id}>
											<Course course={course} onClick={() => setSelectedCourse(course)} />
										</Col>
									))}
								</Row>
							</div>
						))}
					</Container>

					{/* Course Detail Modal */}
					<Modal show={selectedCourse !== null} onHide={() => setSelectedCourse(null)} size="lg" centered>
						{selectedCourse && (
							<>
								<Modal.Header closeButton>
									<Modal.Title className="ingeborgHeader" id="contained-modal-title-vcenter">
										{selectedCourse.title.rendered}
									</Modal.Title>
								</Modal.Header>
								<Modal.Body>
									<div className="course-modal-content">
										{/* Add image if available */}
										{selectedCourse.yoast_head_json?.og_image?.[0]?.url && (
											<img
												src={selectedCourse.yoast_head_json?.og_image?.[0]?.url}
												alt={selectedCourse.title.rendered}
												className="modal-course-image"
												style={{ width: "100px", height: "auto" }}
											/>
										)}
										<div
											dangerouslySetInnerHTML={{
												__html: selectedCourse.content.rendered
													.replace(/<img[^>]*>/g, "") // Remove images
													.replace(/<p>\s*<\/p>/g, "") // Remove empty paragraphs
													.replace(/<p>&nbsp;<\/p>/g, "") // Remove paragraphs with only &nbsp;
													.replace(/\s*<br\s*\/?>\s*<br\s*\/?>\s*/g, "<br>") // Replace multiple breaks with single
													.trim(), // Remove leading/trailing whitespace
											}}
										/>
										<div className="text-left mt-4">
											<ContactModal classes="btn-secondary" btntitle="Anfragen" subject={selectedCourse.title.rendered} />
										</div>
									</div>
								</Modal.Body>
							</>
						)}
					</Modal>
				</section>
			) : (
				<Loading />
			)}
		</div>
	);
};

export default Courses;
