import React from "react";
import { Card, Button } from "react-bootstrap";
import ReactDOMServer from "react-dom/server";

const subtitleStyles = {
	courseSubtitle: {
		fontSize: "16px",
		color: "black",
		overflow: "hidden",
		WebkitLineClamp: 2,
		WebkitBoxOrient: "vertical",
		lineHeight: 1.4,
		margin: "10px 0",
	},
};

const dateStyles = {
	courseDate: {
		fontSize: "16px",
		color: "black",
		margin: "0",
		padding: "0",
		fontWeight: "bold",
	},
};

const Course = ({ course, onClick }) => {
	let title = " ";
	if (course) {
		try {
			title = ReactDOMServer.renderToString(course.title.rendered)
				.replace(/&amp;#8222;/g, "")
				.replace(/&amp;#8220;/g, "");
		} catch {
			title = course.title.rendered;
		}
	}

	console.log(course.content.rendered);

	const imageUrl = course.yoast_head_json?.og_image?.[0]?.url;

	const getSubtitle = () => {
		const match = course.content.rendered.match(/<h3[^>]*>(.*?)<\/h3>/);
		return match ? match[1] : "";
	};

	const getDate = () => {
		const match = course.content.rendered.match(/<h4[^>]*>(.*?)<\/h4>/);
		return match ? match[0] : `<h4>Termin anfragen</h4>`;
	};

	return (
		<Card className="course-card" onClick={onClick} style={{ cursor: "pointer" }}>
			{imageUrl && (
				<Card.Img
					style={{ height: "200px", objectFit: "contain", padding: "10px", paddingTop: "20px" }}
					variant="top"
					src={imageUrl}
					alt={title}
					className="card-img-top"
				/>
			)}
			<Card.Body>
				<div style={{ display: "flex", height: "100%", justifyContent: "space-between", flexDirection: "column" }}>
					<div style={{ height: "100%" }}>
						<Card.Title>
							<h3 style={{ marginBottom: "0", marginTop: "0" }}>{title}</h3>
						</Card.Title>
						<Card.Subtitle
							className="course-subtitle"
							style={subtitleStyles.courseSubtitle}
							dangerouslySetInnerHTML={{
								__html: getSubtitle(),
							}}
						/>
						<p style={dateStyles.courseDate} dangerouslySetInnerHTML={{ __html: getDate() }}></p>
					</div>
					<Button className="caption-buttons" style={{ marginTop: "10px" }}>
						Öffnen
					</Button>
				</div>
			</Card.Body>
		</Card>
	);
};

export default Course;
