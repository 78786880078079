import React from "react";
import { Modal } from "react-bootstrap";

const MyModal = (props) => {
	return (
		<Modal {...props} size="lg" aria-labelledby="contained-modal-title-vcenter" centered>
			<Modal.Header closeButton>
				<Modal.Title className="ingeborgHeader" id="contained-modal-title-vcenter">
					{props.title}
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>{props.children}</Modal.Body>
			{/* <Modal.Footer>
				<Button className="btn-secondary" onClick={props.onHide}>Schließen</Button>
			</Modal.Footer> */}
		</Modal>
	);
};

export default MyModal;
