import React, { useState, useRef } from "react";
import {
	Button,
	Form,
	Container,
	Alert,
	ButtonToolbar,
	// InputGroup,
	// FormControl,
} from "react-bootstrap";
import emailjs from "@emailjs/browser";

function ContactForm(props) {
	const [firstName, setFirstName] = useState("");
	const [lastName, setLastName] = useState("");
	const [email, setEmail] = useState("");
	const [subject, setSubject] = useState(props.props);
	const [message, setMessage] = useState("");
	const [checked, setChecked] = useState(false);
	const [status, setStatus] = useState(null);
	// const [numberOne, setNumberOne] = useState(0);
	// const [numberTwo, setNumberTwo] = useState(0);
	// const [sumOfNumbers, setSumOfNumbers] = useState(0);
	// const [wrongResult, setWrongResult] = useState(true);

	const [loading, setLoading] = useState(false);

	// useEffect(() => {
	// 	if (Number(sumOfNumbers) === Number(numberOne + numberTwo)) {
	// 		setWrongResult(false);
	// 	} else {
	// 		setWrongResult(true);
	// 	}
	// 	if (numberOne === 0) {
	// 		setNumberOne(Math.floor(Math.random() * 10));
	// 		setNumberTwo(Math.floor(Math.random() * 10));
	// 	}
	// }, [sumOfNumbers, numberOne, numberTwo]);

	const form = useRef();

	// const sendEmail = () => {
	// 	window.Email.send({
	// 		SecureToken : "ef77551e-34b5-4841-ba48-643535bb7b88",
	// 		Host: "smtp.elasticemail.com",
	// 		Username: "mail@seinimlicht.de",
	// 		Password: "9C329923BB2534ED9D32AA3E67271C686EC5",
	// 		To: "info@seinimlicht.de",
	// 		From: "sebastian.rassloff@prozess.ai",
	// 		Subject: `Homepage Kontaktformular: ${subject} von: ${email}`,
	// 		Body: message,
	// 	}).then((response) => {
	// 		response === "OK" ? setStatus("success") : setStatus("warning");
	// 		console.log(response)
	// 	}
	// 	);
	// };

	const sendEmail = (e) => {
		e.preventDefault();
		setLoading(true);
		emailjs
			.sendForm(
				"service_8ihm0vs",
				"template_izvth8j",
				form.current,
				"a_HNT8nfaN6I-dR6q"
			)
			.then((response) => {
				response.text === "OK" ? setStatus("success") : setStatus("warning");
				setLoading(false);
			});
	};

	return (
		<Container>
			{status && (
				<Alert variant={status}>
					{status === "success" ? (
						<Alert.Heading>
							Nachricht erfolgreich gesendet. Ich melde mich bei Dir!
						</Alert.Heading>
					) : (
						<Alert.Heading>
							Es ist ein Fehler aufgetreten. Bitte probiere es erneut
						</Alert.Heading>
					)}{" "}
				</Alert>
			)}
			<h2 className="display-6">Kontaktformular</h2>
			{loading && <p>... Deine Nachricht wird gesendet</p>}
			<Form
				style={{
					fontSize: "18px",
					fontFamily: "Lato",
				}}
				ref={form}
			>
				<Form.Group controlId="firstName">
					<Form.Label>Vorname*</Form.Label>
					<Form.Control
						required
						type="name"
						name="firstName"
						placeholder="Vorname"
						value={firstName}
						onChange={(e) => setFirstName(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group controlId="lastName" className="py-2">
					<Form.Label>Nachname</Form.Label>
					<Form.Control
						type="name"
						name="lastName"
						placeholder="Nachname"
						value={lastName}
						onChange={(e) => setLastName(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group controlId="email">
					<Form.Label>E-Mail*</Form.Label>
					<Form.Control
						required
						type="email"
						name="email"
						placeholder="Deine E-Mail Adresse"
						value={email}
						onChange={(e) => setEmail(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group controlId="subject" className="py-2">
					<Form.Label>Betreff*</Form.Label>
					<Form.Control
						required
						type="text"
						name="subject"
						placeholder="..."
						value={subject}
						onChange={(e) => setSubject(e.target.value)}
					></Form.Control>
				</Form.Group>

				<Form.Group controlId="messagefield">
					<Form.Label>Nachricht*</Form.Label>
					<Form.Control
						required
						as="textarea"
						rows={4}
						placeholder="Deine Nachricht"
						name="message"
						value={message}
						onChange={(e) => setMessage(e.target.value)}
					/>
				</Form.Group>

				{/* static infos for email.js */}
				<Form.Group style={{ display: "none" }}>
					<Form.Label>toEmail</Form.Label>
					<Form.Control
						required
						type="email"
						name="toEmail"
						id="toEmail"
						value={"info@seinimlicht.de"}
					/>
				</Form.Group>

				<Form.Group style={{ display: "none" }}>
					<Form.Label>toName</Form.Label>
					<Form.Control required name="toName" id="toName" value={"Daniela"} />
				</Form.Group>
				{/* ENDE stantic info  */}
				<ButtonToolbar className="py-2">
					<Button
						type="button"
						className="btn-primary mt-2 mb-2"
						disabled={
							!checked ||
							!firstName ||
							!email ||
							!subject ||
							!message ||
							loading
						}
						onClick={sendEmail}
					>
						Nachricht senden
					</Button>
{/* 
					<InputGroup className="py-2 px-2">
						<InputGroup.Text id="btnGroupAddon2">
							{numberOne} + {numberTwo} ={" "}
						</InputGroup.Text>

						<FormControl
							style={{ width: "75px" }}
							required
							type="number"
							value={sumOfNumbers}
							onChange={(e) => setSumOfNumbers(e.target.value)}
						/>
					</InputGroup> */}
				</ButtonToolbar>

				<Form.Group controlId="checkbox">
					<Form.Check
						style={{
							fontSize: "14px",
							fontFamily: "Lato",
						}}
						required
						onChange={(e) => setChecked(!checked)}
						type="checkbox"
						label="Ja, ich habe die Datenschutzbestimmungen zur Kenntnis genommen."
					></Form.Check>
				</Form.Group>
			</Form>
		</Container>
	);
}

export default ContactForm;
